import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Flex, useDisclosure, useBreakpoint } from "@chakra-ui/react";

import { Header } from "./header";
import { Sidebar } from "./sidebar";
import { Footer } from "./footer";

type Props = {
  children: React.ReactNode;
};

export const SIDEBAR_WIDTH = 240;

export const Layout = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const screenSize = useBreakpoint() ?? "";

  const disabledHeader = ["/terms", "/privacy-notice"].some(
    path => pathname.indexOf(path) >= 0
  );
  const disabledSidebar = ["/terms", "/privacy-notice"].some(
    path => pathname.indexOf(path) >= 0
  );

  return (
    <Box position="relative">
      <Box minHeight="calc(100vh - 40px)">
        {!disabledHeader && <Header onClose={onClose} onToggle={onToggle} />}
        {disabledSidebar ? (
          <Box>{children}</Box>
        ) : (
          <Flex align="flex-start">
            <Box mr={{ md: SIDEBAR_WIDTH }}>
              <Sidebar
                width={screenSize === "sm" ? "full" : SIDEBAR_WIDTH}
                isOpen={isOpen}
                onClose={onClose}
              />
            </Box>
            <Box
              minWidth={0}
              w="100%"
              p={{ base: "65px 15px 30px", md: "80px 30px 30px 30px" }}
            >
              {children}
            </Box>
          </Flex>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
