import { AfterResponseHook } from "ky";
import debugModule from "debug";
import { parse, stringify } from "qs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";

import { useLocale } from "app/locale";
import { history } from "utils/history";
import { Terms } from "../types";

const debug = debugModule("medmain:terms");

const messages = {
  service: {
    url: "/terms/agreement",
    debugMessage: "Redirect to terms agreement."
  },
  pn: {
    url: "/privacy-notice/agreement",
    debugMessage: "Redirect to privacy notice agreement."
  }
};

export const checkAgreementTerms: AfterResponseHook = async (
  req,
  opts,
  res
) => {
  if (res.status !== 401) return res;

  const data = await res.json();
  if (!["service", "pn"].includes(data.message)) return res;

  const { pathname, search } = window.location;
  const q = parse(search, { ignoreQueryPrefix: true });
  const { url, debugMessage } = messages[data.message];
  const nextLocation = {
    pathname: url,
    search: stringify({ r: pathname === url ? q.r : pathname })
  };
  debug(debugMessage, nextLocation);
  history.replace(nextLocation);
  return res;
};

export const useTermsAgreement = ({ type }: { type: Terms["licenseType"] }) => {
  const locale = useLocale();

  const validationSchema = yup.object().shape({
    ...(type === "service"
      ? {
          termsOfService: yup
            .boolean()
            .oneOf([true], locale.terms.agreementTermsOfServiceError)
        }
      : {}),
    ...(type === "ai"
      ? {
          termsOfAI: yup
            .boolean()
            .oneOf([true], locale.terms.ai.agreementTermsOfAIError)
        }
      : {}),
    ...(type === "pn"
      ? {
          agreement: yup
            .boolean()
            .oneOf([true], locale.privacyNotice.agreementError),
          check1: yup.boolean(),
          check2: yup.boolean()
        }
      : {})
  });

  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  return {
    register,
    errors,
    isSubmitting,
    handleSubmit
  };
};
